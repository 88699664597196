export enum PARSE_DURATION_BASE_UNITS {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
}

const DAY_IN_HOURS = 24;
const HOUR_IN_MINUTES = 60;
const MINUTE_IN_SECONDS = 60;

const DAY_IN_SECONDS = DAY_IN_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS;
const HOUR_IN_SECONDS = HOUR_IN_MINUTES * MINUTE_IN_SECONDS;
const DAY_IN_MINUTES = DAY_IN_HOURS * HOUR_IN_MINUTES;

/**
 * Parse a duration.
 *
 * @param value - Duration.
 * @param unit - Unit of duration param (parseDuration.BASE_UNITS enum).
 * @returns Parsed duration.
 */
export const parseDuration = (
  value: number,
  unit: PARSE_DURATION_BASE_UNITS,
) => {
  const valueForComputation =
    unit === PARSE_DURATION_BASE_UNITS.MINUTE ? value * 60 : value;

  const days = Math.floor(valueForComputation / DAY_IN_SECONDS);
  const hours =
    Math.floor(valueForComputation / HOUR_IN_SECONDS) - days * DAY_IN_HOURS;
  const minutes =
    Math.floor(valueForComputation / MINUTE_IN_SECONDS) -
    (days * DAY_IN_MINUTES + hours * HOUR_IN_MINUTES);
  const seconds =
    valueForComputation -
    (days * DAY_IN_SECONDS +
      hours * HOUR_IN_SECONDS +
      minutes * MINUTE_IN_SECONDS);

  return {
    hours,
    minutes,
    days,
    seconds,
  };
};

parseDuration.BASE_UNITS = PARSE_DURATION_BASE_UNITS;
