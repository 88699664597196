import { useMemo } from 'react';
import { useTimeoutFn } from 'react-use';

import dayjs from '@eversity/services/dayjs';

/**
 * Hook used to exec a function after date.
 *
 * @param date - The date observed to exec the function after.
 * @param func - The function to be executed.
 * @returns See the documentation of useTimeoutFn.
 */
export const useExecAt = (date: string | Date, func: () => any) => {
  const diffms = useMemo(() => dayjs(date).diff(dayjs()), [date]);

  return useTimeoutFn(func, diffms);
};
