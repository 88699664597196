import { useEffect } from 'react';
import Mark from 'mark.js';

import { useMemoizedBundle } from './useMemoizedBundle';

export const useHighlightSearch = (
  textOrTextList: Parameters<Mark['mark']>[0],
  selector: ConstructorParameters<typeof Mark>[0],
  {
    markOptions = {},
    otherDeps = [],
  }: {
    markOptions?: Parameters<Mark['mark']>[1];
    otherDeps?: unknown[];
  } = {},
) => {
  const memoizedOptions = useMemoizedBundle(markOptions);

  useEffect(() => {
    const markInstance = new Mark(selector);

    markInstance.mark(textOrTextList, memoizedOptions);

    return () => markInstance.unmark();
  }, [
    textOrTextList,
    selector,
    memoizedOptions,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only way to do this.
    ...otherDeps,
  ]);
};
