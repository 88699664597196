import { HttpRepository } from '../httpRepository';

const USER_EMAILS_API_URI = '/api/v1/users/user-emails';

const e = encodeURIComponent;

export class UserEmailsRepository extends HttpRepository {
  /**
   * Check if an email address already exists in google directory.
   *
   * @param email - User email.
   * @returns True if the email exists.
   */
  async findExistingEmail(email: string): Promise<boolean> {
    try {
      const { status } = await this.http.get(
        `${USER_EMAILS_API_URI}/${e(email)}`,
      );

      return status === 204;
    } catch (err) {
      // Catch 404 status because that's what we'll be looking for.
      if (err.status === 404) {
        return false;
      }

      throw err;
    }
  }
}
