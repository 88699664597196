import { useMemo } from 'react';

/**
 * Use this hook to memo an array whose reference shouldn't change if its values are the same.
 *
 * @param array - Array to memo.
 * @returns Array.
 */
export const useMemoArray = <T extends unknown[]>(array: T): T =>
  useMemo(
    () => array,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- That's the point of the hook.
    [JSON.stringify(array)],
  );
