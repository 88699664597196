declare global {
  interface Window {
    env: {
      [key: string]: string;
    };
  }
}

/**
 * Returns runtime environment variable if set.
 * Runtime environment is configured in `src/index.html.ejs` inside window.env.
 * They are configured using placeholders as in window.env = { A : '$MY_APP_A' }.
 *
 * @param variableName - The variable name.
 * @returns  The variable value.
 */
export const getEnvironmentVariable = <T = string>(
  variableName: string,
  {
    transform = (v) => v as T,
    defaultValue,
  }: {
    transform?: (v: string) => T;
    defaultValue?: T;
  } = {},
) => {
  let varValue = window.env?.[variableName];

  if (varValue === `$${variableName}` || varValue === '') {
    varValue = undefined;
  }

  if (varValue === undefined && defaultValue !== undefined) {
    return defaultValue;
  }

  return transform(varValue);
};

export default {
  app: {
    name: 'eversity/backoffice',
    env: getEnvironmentVariable('ENVIRONMENT'),
  },

  sentry: {
    dsn: getEnvironmentVariable('BACKOFFICE_WEB_SENTRY_DSN'),
    env: getEnvironmentVariable('ENVIRONMENT'),
    release: `backoffice-web@${process.env.BUILD_VAR_SENTRY_RELEASE}`,
    enabled: getEnvironmentVariable('BACKOFFICE_WEB_SENTRY_ENABLED', {
      transform: (v) => v === 'true',
      defaultValue: getEnvironmentVariable('SENTRY_ENABLED', {
        transform: (v) => v === 'true',
        defaultValue: true,
      }),
    }),
  },
} as const;
