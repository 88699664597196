export enum NEWS_VIEWS {
  STUDENT = 'STUDENT',
  FULL = 'FULL',
}

export enum NEWS_STATUSES {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

// PLANNED has status NEWS_STATUSES.PUBLISHED in db but publicationDate is in the future
export enum NEWS_STATES {
  PUBLISHED = 'PUBLISHED',
  PLANNED = 'PLANNED',
  DRAFT = 'DRAFT',
}

export enum NEWS_CATEGORIES {
  LESSONS = 'LESSONS',
  EXAMS = 'EXAMS',
  COACHING = 'COACHING',
  ARTICLES = 'ARTICLES',
  SCHOOL_LIFE = 'SCHOOL_LIFE',
}

export const NEWS_PICTURE_ASPECT_RATIO = 480 / 245;

export const NEWS_TITLE_MAX_LENGTH = 100;
